/* unplugin-vue-components disabled */import __unplugin_components_0 from 'D:/Projects/118_meijia/frontend/src/components/MoreItemListPopup.vue';
import "core-js/modules/es.number.constructor.js";
import { unref as _unref, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, vShow as _vShow, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue";
export default {
  __name: 'MJCalendarMoreItem',
  props: {
    variant: String,
    // gray
    num: [String, Number],
    popupTitle: String,

    /**
     * @property {EventObject} list[0] 事件物件
     */
    list: Array,
    noEditPopup: Boolean,
    // plugin
    popperConfig: Object,
    // style
    color: String,
    bg: String
  },
  emits: ['eventUpdate', 'eventRemove'],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    var refRoot = ref(null);
    var cData = reactive({
      moreItemListPopupShow: false,
      popupLazy: false,
      show: true
    });
    watchEffect(function () {
      if (!props.num) {
        cData.show = false;
        cData.moreItemListPopupShow = false;
      } else {
        cData.show = true;
      }
    });
    onMounted(function () {
      setTimeout(function () {
        cData.popupLazy = true;
      }, 600);
    });
    return function (_ctx, _cache) {
      var _component_MoreItemListPopup = __unplugin_components_0;

      return _withDirectives((_openBlock(), _createElementBlock("div", {
        ref_key: "refRoot",
        ref: refRoot,
        class: _normalizeClass(["mj-calendar-more-item", [__props.variant ? "mj-calendar-more-item_".concat(__props.variant) : null]]),
        style: _normalizeStyle({
          '--mj-calendar-more-item-bg': __props.bg || __props.variant ? null : __props.num > 2 ? '#06799D' : '#06799d33',
          '--mj-calendar-more-item-color': __props.color || __props.variant ? null : __props.num > 2 ? '#ffffff' : '#06799D'
        }),
        onClick: _cache[3] || (_cache[3] = function ($event) {
          return _unref(cData).moreItemListPopupShow = true;
        })
      }, [_createTextVNode(" + " + _toDisplayString(__props.num) + " 則待約 ", 1), _unref(cData).popupLazy && !__props.noEditPopup ? (_openBlock(), _createBlock(_component_MoreItemListPopup, {
        key: 0,
        show: _unref(cData).moreItemListPopupShow,
        "onUpdate:show": _cache[0] || (_cache[0] = function ($event) {
          return _unref(cData).moreItemListPopupShow = $event;
        }),
        target: _unref(refRoot),
        list: __props.list,
        title: __props.popupTitle,
        "close-on-click-outside": "",
        "popper-config": __props.popperConfig,
        onEventUpdate: _cache[1] || (_cache[1] = function ($event) {
          return _ctx.$emit('eventUpdate', $event);
        }),
        onEventRemove: _cache[2] || (_cache[2] = function ($event) {
          return _ctx.$emit('eventRemove', $event);
        })
      }, null, 8, ["show", "target", "list", "title", "popper-config"])) : _createCommentVNode("", true)], 6)), [[_vShow, _unref(cData).show]]);
    };
  }
};